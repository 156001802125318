import { post } from "./http";

const TaskApi = {
  // 获取学生的考试列表
  userExamIsStudent: params => post(`/index.php?r=mobileapp/examPublicList/userExamIsStudent`, params),

  // 获取年级列表
  getGrades: params => post(`/index.php?r=mobileapp/utils/getGrades`, params),

  // 获取科目列表
  getSubjects: params => post(`/index.php?r=mobileapp/utils/getSubjects`, params),

  // 获取学生一份试卷情况
  getStudentTestPaper: params => post(`/index.php?r=mobileapp/examinationWebCorrection/getStudentTestPaper`, params),

  // 提交学生一份试卷的批改情况
  postCorrectionResults: params => post(`/index.php?r=mobileapp/examinationWebCorrection/postCorrectionResults`, params),

  // 获取学生的一份试卷的题目列表及本人的题目分析
  studentTestPaperQuestion: params => post(`/index.php?r=mobileapp/examPublicList/studentTestPaperQuestion`, params),
}
export default TaskApi;